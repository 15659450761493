import React, { useState } from 'react'
import { createLog } from '../../redux/services/adminService';
import { Button, Input, ModalHeader } from 'reactstrap';
import { ModalBody, ModalFooter } from 'react-bootstrap';

const LogsModal = (props) => {
  const { success, failure, token, setContinue, accessedFor, setModal } = props;
  const [data, setData] = useState({ accessedFor: accessedFor });

  const handleChange = (e) => {
    setData(Object.assign({}, data, { [e.target.name]: e.target.value }));
  };

  const handleSubmitLog = async () => {
    if (data.content) {
      let log = await createLog(token, data)
      if (log.code == 5) {
        success(log.message)
        setContinue(accessedFor);
        setModal(false)
      } else {
        failure(log.error)
      }
    }else{
      failure({message:"Write Message"})
    }
  }

  return (
    <div>
      <ModalHeader>Record Log</ModalHeader>
      <ModalBody>
        <Input
          type={"text"}
          name="content"
          placeholder="Accessed For"
          className="form-control m-1 "
          value={data.content}
          onChange={handleChange}
        />
      </ModalBody>
      <ModalFooter>
        <Button className="btn btn-success" onClick={handleSubmitLog}>
          Submit
        </Button>
      </ModalFooter>
    </div>
  )
}

export default LogsModal
