import React, { useEffect, useState } from 'react';
import { CONFIG } from "../../constants/general";
import { Button, Input } from 'reactstrap';
import Pagination from '../user/Pagination';
import { deleteUserLogs, getActivityLogs } from '../../redux/services/adminService';
import { useDispatch, useSelector } from 'react-redux';
import navigation_action from '../../redux/actions/navigation_action';
import LogsModal from './LogsModal';
import { Modal } from 'reactstrap';

const ActivityLogs = () => {
  const { token, currentUser } = useSelector(state => state.auth)
  const [logs, setLogs] = useState([]);
  const [filter, setFilter] = useState({ page: 0, limit: 50 });
  const dispatch = useDispatch()
  const success = (x) => dispatch(navigation_action.fetch_success(x))
  const failure = (x) => dispatch(navigation_action.fetch_failure(x))
  const [selectedRows, setSelectedRows] = useState([]);
  const [access, setAccess] = useState(null);
  const [accessFor, setAccessFor] = useState(null);
  const [logModal, setLogModal] = useState(false);

  const handleCheckboxChange = (e, index) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedRows([...selectedRows, index]);
    } else {
      setSelectedRows(selectedRows.filter((rowIndex) => rowIndex !== index));
    }
  };

  const getLogs = async () => {
    let result = await getActivityLogs(token, filter);
    setFilter({ ...filter, "totalRecords": result.totalRecords, "totalPages": result.totalPages })
    setLogs(result.logs);
  };

  const deleteAllLogs = async () => {
    let result = await deleteUserLogs(token, { deleteAll: true })
    if (result.code == 5) {
      await getLogs()
      setAccess(null)
      success(result.message)
    } else {
      failure(result.error)
    }
  }

  const deleteSelectedRows = async () => {
    let result = await deleteUserLogs(token, { logs: selectedRows })
    if (result.code == 5) {
      await getLogs()
      setSelectedRows([])
      setAccess(null)
      success(result.message)
    } else {
      failure(result.error)
    }
  }

  const handleClick = (x) => {
    let checkSuperAdmin = currentUser.userEmail == "admin@family.in"
    if (checkSuperAdmin) {
      setAccess(x)
    }
    if (access != x && !checkSuperAdmin) {
      setLogModal(true)
      setAccessFor(x)
    }
  }

  useEffect(() => {
    if (access == "DeleteUserLogsAll") {
      setLogModal(false)
      deleteAllLogs()
    } else if (access == "DeleteUserLogs") {
      deleteSelectedRows()
      setLogModal(false)
    }
  }, [access])

  useEffect(() => {
    const getData = setTimeout(getLogs, 500)
    return () => clearTimeout(getData)
  }, [JSON.stringify(filter)]);

  const handleFilterChange = (name, value) => {
    setFilter({ ...filter, [name]: value });
  };

  return (
    <div>
      <div className=''>
        <h1 className='d-flex justify-content-center'>User Logs</h1>
        <div className='d-flex mb-2' style={{ "justifyContent": "space-between" }}>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className='d-flex align-items-center gap-3'>
              Showing {" "}
              <Input
                type="select"
                name="limit"
                id="limit"
                value={filter.limit}
                className="col-3"
                onChange={e => {
                  handleFilterChange("limit", e.target.value);
                }}
              > <>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={150}>150</option>
                  <option value={200}>200</option>

                </>
              </Input>
              {" "}Out of {filter.totalRecords}
            </div>
          </div>
          <Input style={{ width: "30%" }} type='text' name="name" value={filter?.name} onChange={(e) => handleFilterChange("name", e.target.value)} placeholder='Search By Name' />
          <Button className='btn btn-success' onClick={() => { handleClick("DeleteUserLogsAll") }}>Delete All</Button>
        </div>
        <table className='table table-sm table-striped table-bordered table-hover' style={{
          "borderCollapse": "collapse",
          "width": "100%",
          "border": "1px solid",
          "tableLayout": "auto"
        }}>
          <thead>
            <tr>
              <th style={{
                "border": "1px solid",
                "whiteSpace": "nowrap",
                "maxWidth": "200px"
              }}>Select</th>
              <th style={{
                "border": "1px solid",
                "whiteSpace": "nowrap",
                "maxWidth": "200px"
              }}>User Name</th>
              <th style={{
                "border": "1px solid",
                "whiteSpace": "nowrap",
                "maxWidth": "200px",
                "minWidth": "100px"
              }}>User IP</th>
              <th style={{
                "border": "1px solid",
                "whiteSpace": "nowrap",
                "maxWidth": "200px"
              }}>URL Visited</th>
              <th style={{
                "border": "1px solid",
                "whiteSpace": "nowrap",
                "maxWidth": "200px"
              }}>Loading Time</th>
              <th style={{
                "border": "1px solid",
                "whiteSpace": "nowrap",
                "maxWidth": "200px"
              }}>Time Spent</th>
              <th style={{
                "border": "1px solid",
                "whiteSpace": "nowrap",
                "maxWidth": "200px"
              }}>Date & Time</th>
            </tr>
          </thead>
          <tbody>
            {logs.length > 0 && logs.map((x, i) => (
              <tr key={i}>
                <td style={{
                  "border": "1px solid",
                  "whiteSpace": "normal",
                  "maxWidth": "300px",
                  "overflow": "hidden",
                  "textOverflow": "ellipsis",
                  "wordBreak": "break-word"
                }}>
                  <input
                    type="checkbox"
                    value={x?.logs?._id}
                    onChange={(e) => handleCheckboxChange(e, x.logs._id)}
                    checked={selectedRows.includes(x.logs._id)}
                  />
                </td>
                <td style={{
                  "border": "1px solid",
                  "whiteSpace": "normal",
                  "maxWidth": "300px",
                  "overflow": "hidden",
                  "textOverflow": "ellipsis",
                  "wordBreak": "break-word"
                }}>{x.name}</td>
                <td style={{
                  "border": "1px solid",
                  "whiteSpace": "normal",
                  "maxWidth": "300px",
                  "overflow": "hidden",
                  "textOverflow": "ellipsis",
                  "wordBreak": "break-word"
                }}>{x?.logs?.ip}</td>
                <td style={{
                  "border": "1px solid",
                  "whiteSpace": "normal",
                  "maxWidth": "300px",
                  "overflow": "hidden",
                  "textOverflow": "ellipsis",
                  "wordBreak": "break-word"
                }}>{CONFIG.REACT_APP_ENDPOINT + x?.logs?.pathname}</td>
                <td style={{
                  "border": "1px solid",
                  "whiteSpace": "normal",
                  "maxWidth": "300px",
                  "overflow": "hidden",
                  "textOverflow": "ellipsis",
                  "wordBreak": "break-word"
                }}>{x?.logs?.loadingTime / 1000}</td>
                <td style={{
                  "border": "1px solid",
                  "whiteSpace": "normal",
                  "maxWidth": "300px",
                  "overflow": "hidden",
                  "textOverflow": "ellipsis",
                  "wordBreak": "break-word"
                }}>{x?.logs?.timeSpant / 1000}</td>
                <td style={{
                  "border": "1px solid",
                  "whiteSpace": "normal",
                  "maxWidth": "300px",
                  "overflow": "hidden",
                  "textOverflow": "ellipsis",
                  "wordBreak": "break-word"
                }}>{(new Date(x?.logs?.createdAt)).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ "position": "fixed", "top": "90%" }}>
          {
            selectedRows.length > 0 && <>
              <Button className='btn btn-danger' onClick={() => { handleClick("DeleteUserLogs") }}>
                Delete
              </Button>
            </>
          }
        </div>

        <Pagination
          // limit={filter.limit}
          page={filter.page}
          pages={filter.totalPages}
          setPage={(e) => {
            handleFilterChange("page", e);
          }}
        />
      </div>
      <Modal isOpen={logModal}>
        <LogsModal setModal={setLogModal} token={token} setContinue={setAccess} accessedFor={accessFor} success={success} failure={failure} />
      </Modal>
    </div>
  );
};

export default ActivityLogs;
