import React, { lazy, Suspense, useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import authActions, { logoutAction } from "../redux/actions/auth_action";
import dataActions from "../redux/actions/data_action";
import navActions from "../redux/actions/navigation_action";
import { getData } from "../redux/services/appService.js";
import Loader from "../components/loader";
import UserRoute from "./userRoutes";
import AdminRoute from "./adminRouter";
import Alert from "../components/Alert";
import recommendation from "../components/user/recommendation.js";
import familyMemberList from "../components/user/familyMemberList.js";
import matchRecommendation from "../components/user/matchRecommendation.js";
import Membership from "../components/membership.js";
import ActivityLogs from "../components/admin/ActivityLogs.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import NotFound from "../components/NotFound.js";
import errorPage from "../components/errorPage.js";
import ViewHoroscopes from "../components/user/ViewHoroscopes.js";
import ViewFamilyTree from "../components/user/ViewFamilyTree.js";
import FamilyFinding from "../components/user/familyFinding.js";
import SessionTimeout from "../components/helper/sessionTimeout.js";

const Registration = lazy(() => import("../components/registration"));
const Register = lazy(() => import("../components/user/register"));
const Login = lazy(() => import("../components/login"));
const ForgotPassword = lazy(() => import("../components/ForgotPassword"));
const Dashboard = lazy(() => import("../components/user/dashboard"));
// const RequestsList = lazy(() => import("../components/user/requestList"));
const Profile = lazy(() => import("../components/user/Profile"));
const UsersList = lazy(() => import("../components/user/profileList"));
const BlockedUser = lazy(() => import("../components/user/blockedUser"));
const SearchProfile = lazy(() => import("../components/user/SearchProfile"));
const ViewProfile = lazy(() => import("../components/user/viewProfile"));
const UserSetting = lazy(() => import("../components/user/userSetting"));
const Comments = lazy(() => import("../components/user/commets"));
const Notifications = lazy(() => import("../components/user/notifications"));
const Feed = lazy(() => import("../components/user/feed"));
// const Messages = lazy(() => import("../components/user/Messages"));
const AdminLogin = lazy(() => import("../components/admin/adminLogin"));
const AdminLayout = lazy(() => import("../components/admin/adminLayout"));
const RequestsList = lazy(() => import("../components/user/message"));
const Messages = lazy(() => import("../components/user/Messages"));
const MailList = lazy(() => import("../components/user/mailHistory"));
const Chat = lazy(() => import("../components/user/chat"));
const FamilyTree = lazy(() => import("../components/user/familyTree"));
const NewMatches = lazy(() => import("../components/user/newMatches"));
const UserLike = lazy(() => import("../components/user/userLike"));
const ProfilePreview = lazy(() => import("../components/user/profilePreview"));
const FAQs = lazy(() => import("../components/user/faqs"));
const Mailbox = lazy(() => import("../components/user/mailbox"));

const Routes = (props) => {
  const { check, history, setData, failure, logout } = props;
  const [loading, setLoading] = useState(true);
  const loadData = async () => {
    try {
      const data = await getData();
      if (data) {
        setData(data);
        setLoading(false);
      } else {
        failure({ message: "Server Error" });
      }
    } catch (error) {
      failure({ message: "Server Error" });
    }
  };


  const checkLogin = async () => {
    await check(history);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await checkLogin();
      await loadData();
    };

    fetchData();
  }, []);

  const RedirectToHome = () => {
    useEffect(() => {
      window.location.href = "/home.html";
    }, []);
    return null;
  };

  return (
    !loading && (
      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Alert {...props} />
        <SessionTimeout logout={logout}/>
        <Suspense fallback={<Loader {...props} />}>
          <Switch>
            <Route path="/" exact component={RedirectToHome} />
            <Route path="/registration" exact component={Registration} />
            <Route path="/adminlogin" exact component={AdminLogin} />
            <Route path="/login" exact component={Login} />
            <Route path="/forgotpassword" exact component={ForgotPassword} />
            <Route path="/404" component={NotFound} />
            <Route path="/error" component={errorPage} />
            <UserRoute path="/membership" exact component={Membership} />
            <UserRoute path="/register" exact component={Register} />
            <UserRoute path="/dashboard" exact component={Dashboard} />
            <UserRoute path="/profile" exact component={Profile} />
            <UserRoute path="/searchProfile" exact component={SearchProfile} />
            <UserRoute path="/notifications" exact component={Notifications} />
            <UserRoute path="/feed" exact component={Feed} />
            <UserRoute path="/viewHoroscope/:id" exact component={ViewHoroscopes} />
            <UserRoute path="/usersList/:type" exact component={UsersList} />
            <UserRoute path="/requests/:type/:status" exact component={RequestsList} />
            <UserRoute path="/viewprofile/:id" exact component={ViewProfile} />
            <UserRoute path="/setting" exact component={UserSetting} />
            <UserRoute path="/blockedUser" exact component={BlockedUser} />
            <UserRoute path="/comments/:id" exact component={Comments} />
            <UserRoute path="/messages/:id" exact component={Messages} />
            <UserRoute path="/requests" exact component={RequestsList} />
            <UserRoute path="/mailList" exact component={MailList} />
            <UserRoute path="/chat" exact component={Chat} />
            <UserRoute path="/tree" exact component={FamilyTree} />
            <UserRoute path="/:id/tree" exact component={ViewFamilyTree} />
            <UserRoute path="/newMatches" exact component={NewMatches} />
            <UserRoute path="/familyFinding" exact component={FamilyFinding} />
            <UserRoute path="/matchRecommendation" exact component={matchRecommendation} />
            <UserRoute path="/userLike" exact component={UserLike} />
            <UserRoute path="/profilePreview" exact component={ProfilePreview} />
            <UserRoute path="/faqs" component={FAQs} />
            <UserRoute path="/mailbox" component={Mailbox} />
            <UserRoute path="/familyRecommendation" component={recommendation} />
            <UserRoute path="/familyMember" component={familyMemberList} />
            <AdminRoute path="/admin" component={AdminLayout} />
          </Switch>
        </Suspense>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  currentUser: state.auth.userData,
  loader: state.nav.loader.isopen
});

const mapDispatchToProps = (dispatch) => ({
  logout: (history) => dispatch(logoutAction(history)),
  check: (history) => dispatch(authActions.checkLoginAction(history)),
  success: (props) => dispatch(navActions.fetch_success(props)),
  failure: (props) => dispatch(navActions.fetch_failure(props)),
  setData: (props) => dispatch(dataActions.set_data(props)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes));
